import React, { useState, useRef, useCallback, useEffect } from 'react';
import { PageTypes, BootstrapSizes } from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Textbox,
  Textarea,
  Autocomplete,
  Button,
  GridView,
  ToolbarButtons,
  RadioButton,
} from 'ui/components';
// @ts-expect-error
import { Message, Page, GridviewColumns } from 'ui/Helpers/interfaces.ts';
// @ts-expect-error
import { ResponseStatus, Theme, ColumnTypes } from 'ui/Helpers/enums.ts';
// @ts-expect-error
import { GrupoLinha } from 'core/interfaces/TEL/grupoLinha.ts';
// @ts-expect-error
import { Chip } from 'core/interfaces/TEL/chip.ts';
// @ts-expect-error
import { IGrupoLinhaChip } from 'core/interfaces/TEL/grupoLinhaChip.ts';
import { getChipAutoComplete } from 'core/services/TEL';
// import { criarDemandasBloqueioLinhaInadimplencia } from 'core/services/HELP';
import { getGrupoLinha, saveGrupoLinha } from 'core/services/TEL/grupoLinha';
// @ts-expect-error
import ModalImportarLinhas from './modalImportarLinhas.tsx';

interface IDataChip {
  chip: Chip;
  nrSeqChip: number;
  noObservacoes: string;
}

interface IShowModalData {
  // eslint-disable-next-line no-undef
  grupoLinhasChips: Array<Partial<IGrupoLinhaChip>>;
  erros: Array<string>;
}

interface IShowModal {
  show: boolean;
  data?: IShowModalData;
}

export default function GrupoLinhaItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}: Page) {
  const formSubmit = useRef<any>();
  const gridView = useRef<any>();
  const gridViewErrors = useRef<any>();
  // eslint-disable-next-line no-undef
  const [data, setData] = useState<Partial<GrupoLinha> | GrupoLinha>({
    noObservacoes: '',
  });
  // eslint-disable-next-line no-undef
  const [dataChip, setDataChip] = useState<IDataChip | Partial<IDataChip>>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);
  const [
    showModalImportarLinhas,
    setShowModalImportarLinhas,
  ] = useState<IShowModal>({
    show: false,
  });
  const [addLinhasMassiva, setAddLinhasMassiva] = useState<string>('F');
  const [linhasPortadas, setLinhasPortadas] = useState<string>('');

  const onNew = (): void => {
    setData({ noObservacoes: '' });
    setLinhasPortadas('');
    setAddLinhasMassiva('F');
    gridView?.current?.setDataSource([]);
    gridViewErrors?.current?.setDataSource([]);
  };

  const onSetMessage = (status: number, messageShow: string): void => {
    if (messageShow)
      setMessage({
        message: messageShow,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async (): Promise<void> => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const grupoLinha: GrupoLinha = await getGrupoLinha(registryKey);

      setData(grupoLinha);

      gridView?.current?.setDataSource(grupoLinha.grupoLinhasChip ?? []);

      setLoading(false);
    } else {
      onNew();
    }

    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func(): Promise<void> {
      await load();
    })();
  }, [load, registryKey, reload]);

  const save = async (): Promise<void> => {
    setLoading(true);

    const grupoLinhasChip = gridView.current.getDataSource() ?? [];

    if (data.noDescricao !== null && data.noDescricao.trim() !== '') {
      const { status, message: msg, grupoLinha } = await saveGrupoLinha({
        ...data,
        grupoLinhasChip,
      });

      if (status === ResponseStatus.Success) {
        // @ts-expect-error
        gridView?.current?.setDataSource(grupoLinha.grupoLinhasChip);

        setData(grupoLinha);
      }

      onSetMessage(status, msg);
    }

    setLoading(false);
  };

  const onSearchChip = async (e: string): Promise<Array<any>> => {
    const { status, message: msg, chips } = await getChipAutoComplete({
      nrPrefixoLinha: e,
      flgStatus: 'A',
    });

    if (status === ResponseStatus.Success) {
      onSetMessage(status, msg);
    }

    return chips;
  };

  const addGrupoLinhaChip = (): void => {
    if (dataChip.nrSeqChip) {
      const dataSource = gridView?.current?.getDataSource() ?? [];

      const alreadyAdded = dataSource.some(
        (e: any) => e.nrSeqChip === dataChip.chip?.nrSeqChip
      );

      if (alreadyAdded) {
        onSetMessage(ResponseStatus.Error, 'Linha já foi adicionada ao Grupo');
      } else {
        dataSource.push({ ...dataChip, status: 'Inserir' });

        gridView.current.setDataSource(dataSource);
      }

      setDataChip({});
    }
  };

  const removerLinhaContratoMassivo = (e: any, datasource: any): void => {
    const gridItem = datasource.find((el: any) => el === e);

    const list = datasource.filter((el: any) => el !== e);

    if (gridItem.status !== 'Inserir') {
      gridItem.status = 'Remover';

      list.push(gridItem);
    }

    gridView.current.setDataSource(list);
  };

  const columns: Array<GridviewColumns> = [
    {
      key: 'chip.linhaFormatada',
      title: 'Linha',
    },
    {
      key: 'chip.operadora.noOperadora',
      title: 'Operadora',
    },
    {
      key: 'noObservacoes',
      title: 'Observações',
    },
    {
      key: 'nrSeqGrupoLinhaChip',
      type: ColumnTypes.Button,
      onClick: (e: any, dataSource: any) =>
        removerLinhaContratoMassivo(e, dataSource),
      title: 'Excluir',
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const errorsColumns: Array<GridviewColumns> = [
    { key: 'error', title: 'Erros', filterable: true },
  ];

  useEffect(() => {
    if (showModalImportarLinhas.data) {
      showModalImportarLinhas.data.grupoLinhasChips.forEach((item: any) => {
        item.chip.linhaFormatada = `(${item.chip.nrPrefixo}) ${item.chip.nrLinha}`;
        item.status = 'Inserir';
      });

      gridView?.current?.setDataSource(
        showModalImportarLinhas.data.grupoLinhasChips
      );

      const errorList =
        showModalImportarLinhas.data.erros.map((e: string) => ({
          error: e,
        })) ?? [];
      gridViewErrors?.current?.setDataSource(errorList);
    }
  }, [showModalImportarLinhas.data]);

  const addMultipleLinesButtons = [
    { text: 'Selecionar uma Linha', value: 'F' },
    { text: 'Adicionar Lista de Linhas', value: 'V' },
  ];

  const addLinhas = async () => {
    const dataSource = gridView?.current?.getDataSource() ?? [];

    if (linhasPortadas === '') {
      onSetMessage(ResponseStatus.Error, 'Informe ao menos uma linha');
    } else {
      const listaLinhas = linhasPortadas
        .replace(/ {2}/g, '\t')
        .replace(/[()\-,. ]/g, '')
        .split(/\r?\n/)
        .map((e) => e.split(/\r?\t| +/))
        .filter((e) => e.length > 0 && e[0] !== '');

      const linhasValidas = listaLinhas.every(
        (linha) => linha[0].length === 11
      );

      if (!linhasValidas) {
        onSetMessage(
          ResponseStatus.Error,
          'Linha invalida informada, Todas linhas devem ter 11 digitos contando o prefixo'
        );
      } else {
        const promises: Array<Promise<any>> = [];

        listaLinhas.forEach((linha) => {
          promises.push(
            getChipAutoComplete({
              nrPrefixoLinha: linha[0],
              flgStatus: 'A',
            })
          );
        });

        const result = await Promise.all(promises);

        const errors: any = [];

        result.forEach((res, index) => {
          if (res.chips.length > 0) {
            const chip = res.chips[0];
            const obj = {
              nrSeqChip: chip.nrSeqChip,
              chip,
              noObservacoes:
                listaLinhas[index].length > 1
                  ? listaLinhas[index][1]
                  : chip.linhaFormatada,
              status: 'Inserir',
            };

            if (
              !dataSource.some(
                (e: any) => e?.chip?.nrSeqChip === obj.chip?.nrSeqChip
              )
            ) {
              dataSource.push(obj);
            } else {
              errors.push({
                error: `Linha (${obj.chip.nrPrefixo}) ${obj.chip.nrLinha} já foi adicionada a esse grupo de linhas`,
              });
            }
          } else {
            errors.push({
              error: `Linha ${listaLinhas[index][0]} não encontrada ou chip não esta ativo`,
            });
          }
        });

        gridView?.current?.setDataSource(dataSource);

        setLinhasPortadas('');

        if (errors.length > 0) {
          gridViewErrors?.current?.setDataSource(errors ?? []);

          onSetMessage(
            ResponseStatus.Error,
            'Uma ou mais linhas não foram adicionadas ao grupo. Verifique o motivo na lista de erros'
          );
        }
      }
    }
  };

  const { id: idSelecao } = PageTypes.Selection;

  return (
    // @ts-expect-error
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Grupo de Linhas'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => onNew()}
      onSave={save}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <ToolbarButtons fixedOnTop>
        <ToolbarButtons.Button
          text='Importar Linhas'
          onClick={() => setShowModalImportarLinhas({ show: true })}
        />
      </ToolbarButtons>
      <div className='row mb-3'>
        <div className='col'>
          {/* @ts-ignore */}
          <Textbox
            label='Descrição'
            required
            maxLength={4000}
            text={data.noDescricao}
            onChangedValue={(noDescricao: string) =>
              setData({ ...data, noDescricao })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          {/* @ts-ignore */}
          <Textarea
            label='Observações'
            maxLength={4000}
            text={data.noObservacoes}
            onChangedValue={(noObservacoes: string) =>
              setData({ ...data, noObservacoes })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-auto'>
          {/* @ts-ignore */}
          <RadioButton
            outline
            size={BootstrapSizes.Large}
            theme={Theme.Primary}
            buttons={addMultipleLinesButtons}
            selectedButton={addLinhasMassiva}
            onChange={(value: string) => {
              setAddLinhasMassiva(value);
            }}
          />
        </div>
      </div>
      {addLinhasMassiva === 'V' ? (
        <div className='row'>
          <div className='col'>
            {/* @ts-ignore */}
            <Textarea
              label='N° Linhas'
              maxLength={2000}
              rows={3}
              placeholder='Insira a lista com n° das linhas'
              text={linhasPortadas}
              onChangedValue={(linhas: string) => setLinhasPortadas(linhas)}
            />
          </div>
          <div className='col-2'>
            <Button
              outline
              visible={data.flgPermiteAlterar}
              icon='plus'
              className='mt-4'
              size={BootstrapSizes.Medium}
              theme={Theme.Success}
              onClick={addLinhas}
              text='Adicionar Linhas'
            />
          </div>
        </div>
      ) : (
        <div className='row mb-3'>
          <div className='col'>
            {/* @ts-ignore */}
            <Autocomplete
              label='Prefixo/Número'
              searchDataSource={onSearchChip}
              selectedItem={dataChip.chip}
              onSelectItem={(chip: any) =>
                setDataChip({ ...dataChip, nrSeqChip: chip.nrSeqChip, chip })
              }
              dataSourceTextProperty='linhaFormatada'
            />
          </div>
          <div className='col'>
            {/* @ts-ignore */}
            <Textbox
              label='Observações da Linha'
              text={dataChip.noObservacoes}
              maxLength={300}
              onChangedValue={(noObservacoes: string) =>
                setDataChip({ ...dataChip, noObservacoes })
              }
            />
          </div>
          <div className='col d-flex align-items-end'>
            {/* @ts-ignore */}
            <Button
              icon='plus'
              className='px-3 '
              theme={Theme.Success}
              size={BootstrapSizes.Medium}
              onClick={() => addGrupoLinhaChip()}
            />
          </div>
        </div>
      )}

      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            // @ts-expect-error
            dataColumns={columns}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridViewErrors}
            // @ts-expect-error
            dataColumns={errorsColumns}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
      </div>
      <ModalImportarLinhas
        show={showModalImportarLinhas.show}
        onClose={() =>
          setShowModalImportarLinhas({
            ...showModalImportarLinhas,
            show: false,
          })
        }
        setData={setShowModalImportarLinhas}
        onSetMessage={onSetMessage}
      />
    </CSDManutPage>
  );
}
