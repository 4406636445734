import { getVencimentoAutoComplete } from 'core/services/ASS/vencimento';
import { getClienteAutoCompleteDemanda } from 'core/services/FIN';
import { getInformacaoBancariaAutoCompleteFiltraFormaPagamento } from 'core/services/FIN/informacaoBancaria';
import { getTituloEspecieAutoComplete } from 'core/services/FIN/tituloEspecie';
import {
  getAparelhoAutoCompleteForDemanda,
  getCentroDeCustoAutoCompleteSimples,
  getClienteLinhasByCliente,
  getPlanoAutoCompleteDemanda,
  getTipoChamadaAutoCompleteResumido,
} from 'core/services/TEL';
import { getPlanoModeloAparelhoAutoCompleteForDemanda } from 'core/services/TEL/planoModeloAparelho';
import { getPlanoVinculoCentrodeCustoEmpAutoComplete } from 'core/services/TEL/planoVinculoCentrodeCustoEmp';
import React, { useEffect, useState, useRef } from 'react';
import { MaskTypes } from 'ui/Helpers/masks';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import {
  BootstrapSizes,
  MimeTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  Autocomplete,
  Button,
  FileUpload,
  GridView,
  Textbox,
  Switch,
  DatePicker,
  Panel,
} from 'ui/components';

export default function ManutencaoAlteracaoContrato({
  data,
  setData,
  onSetMessage,
  setDataListDocRemove,
  documentos = [],
  setDocumentos,
  onOpenTransaction,
  findTransaction,
  onSelectCliente,
  onSelectFormaPagamento,
  setLoading,
  onAutoCompleteFormaPagamento,
}) {
  const gridViewDocumentos = useRef();
  const [importedFile, setImportedFile] = useState([]);
  const [hasPlanoRateioDefault, setHasPlanoRateioDefault] = useState(false);
  const [darEntrada, setDarEntrada] = useState(false);
  const [tituloReceber, setTituloReceber] = useState({});

  const onSearchCliente = async (e) => {
    const { clientes } = await getClienteAutoCompleteDemanda({
      noPessoa: e,
      flgIncluirEndereco: true,
      flgFiltrarEmpresa: true,
    });
    return clientes;
  };

  const onSearchPlanoOperadoraDestivo = async (e) => {
    const { status, message: msg, planos } = await getPlanoAutoCompleteDemanda({
      noPlano: e,
      nrSeqOperadora: data.nrSeqOperadora ?? data.nrSeqOperadoraDestino,
      flgAgrupado: true,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });

    if (planos.length === 0)
      onSetMessage(ResponseStatus.Error, 'Nenhum plano vinculado a empresa.');

    onSetMessage(status, msg);

    return planos;
  };

  const onSearchPacoteDados = async (e) => {
    if (data.nrSeqPlano === undefined || data.nrSeqPlano === 0) {
      onSetMessage(ResponseStatus.Error, 'Selecione o Plano.');
      return [];
    }

    if (!data.nrSeqEmpresa) {
      onSetMessage(ResponseStatus.Error, 'Selecione a Empresa');

      return [];
    }
    if (data.nrSeqCentroDeCusto) {
      const {
        planovinculo,
      } = await getPlanoVinculoCentrodeCustoEmpAutoComplete({
        nrSeqPlano: data.plano?.nrSeqPlano,
        nrSeqOperadora: data.nrSeqOperadoraDestino,
        nrSeqEmpresa: data.nrSeqEmpresa,
        nrSeqCentroDeCusto: data.nrSeqCentroDeCusto,
        noTipoChamada: e,
      });
      if (planovinculo.length === 0) {
        onSetMessage(
          ResponseStatus.Error,
          'Centro de Custo sem pacote vinculado nessa Empresa.'
        );
      }

      return planovinculo;
    }
    const { tiposChamadas } = await getTipoChamadaAutoCompleteResumido({
      nrSeqPlano: data.plano?.nrSeqPlano,
      nrSeqOperadora: data.nrSeqOperadoraDestino,
      flgBuscaEncargo: true,
      noTipoChamada: e,
    });

    if (tiposChamadas.length === 0) {
      onSetMessage(
        ResponseStatus.Error,
        'Nenhum pacote de dados encontrado para esse plano'
      );
    }

    return tiposChamadas;
  };

  const onSearchPlanoRateio = async (e) => {
    const { status, message: msg, planos } = await getPlanoAutoCompleteDemanda({
      noPlano: e,
      flgAtivo: true,
      flgPlanoRateioEmpresa: true,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });

    if (planos.length === 0)
      onSetMessage(ResponseStatus.Error, 'Nenhum plano vinculado a empresa.');

    onSetMessage(status, msg);

    return planos;
  };

  const getPlanoRateio = async (nrSeqTipoChamada) => {
    const { planos } = await getPlanoAutoCompleteDemanda({
      nrSeqTipoChamada,
      flgAtivo: true,
      flgPlanoRateioEmpresa: true,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });

    return planos.length > 0 ? planos[0] : undefined;
  };

  const onClickAddDocumento = async () => {
    if (importedFile.length > 0) {
      const documento = {
        ...importedFile,
        noArquivo: importedFile[0].name,
        noTipoArquivo: importedFile[0].type,
        noTamanho: importedFile[0].size,
        flgExibeDownload: false,
        status: GridView.EnumStatus.Inserir,
      };

      const dataSource = gridViewDocumentos?.current?.getDataSource() ?? [];

      dataSource.push(documento);

      if (gridViewDocumentos && gridViewDocumentos.current) {
        gridViewDocumentos.current.setDataSource(dataSource);
      }

      setImportedFile([]);

      setDocumentos([...documentos, importedFile[0]]);
    }
  };

  const onClickDownload = async (e) => {
    const downloadLink = document.createElement('a');

    if (e.noImagem !== null && e.noImagem !== '') {
      const linkSource = `data:image/png;base64, ${e.noImagem}`;
      downloadLink.href = linkSource;
      downloadLink.download = e.noArquivo;
    } else if (e.linkDownload !== null && e.linkDownload !== '') {
      downloadLink.href = e.linkDownload;
      downloadLink.target = '_blank';
    }

    downloadLink.click();
  };

  const onRemoverDocumento = async (e, datasource) => {
    const gridItem = datasource.find((el) => el === e);

    const list = datasource.filter((el) => el !== e);

    if (gridItem.nrSeqDemandaDocumento) {
      gridItem.status = GridView.EnumStatus.Remover;

      list.push(gridItem);

      const nrSeqsToRemove = list
        .filter((item) => item.status === GridView.EnumStatus.Remover)
        .map((item) => item.nrSeqDemandaDocumento);

      setDataListDocRemove(nrSeqsToRemove);
    } else {
      const docList = documentos.filter(
        (el) =>
          el.name !== gridItem.name &&
          el.type !== gridItem.type &&
          el.size !== gridItem.size
      );
      setDocumentos(docList);
    }

    if (gridViewDocumentos && gridViewDocumentos.current)
      gridViewDocumentos.current.setDataSource(list);
  };

  const searchClienteLinhas = async (e) => {
    const obj = {
      nrPrefixoLinha: e,
    };

    if (data.nrSeqPessoaCli) {
      obj.nrSeqPessoaCli = data.nrSeqPessoaCli;
    }
    if (data.nrSeqEmpresa) {
      obj.nrSeqEmpresa = data.nrSeqEmpresa;
    }

    const {
      status,
      message: msg,
      data: linhas,
    } = await getClienteLinhasByCliente(obj);

    if (linhas.length === 0)
      onSetMessage(
        ResponseStatus.Error,
        'Cliente não possui linhas cadastradas nessa empresa'
      );

    onSetMessage(status, msg);

    return linhas;
  };

  const onSearchCentroDeCusto = async (e) => {
    const {
      status,
      message: msg,
      data: centros,
    } = await getCentroDeCustoAutoCompleteSimples({
      codNoCentroDeCusto: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });

    onSetMessage(status, msg);

    return centros;
  };

  const columnsDocumentos = [
    { key: 'noArquivo', title: 'Documento' },
    {
      key: 'nrSeqDemandaDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickDownload(e, datasource),
      theme: Theme.Success,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
      visibleDynamic: 'flgExibeDownload',
    },
    {
      key: 'nrSeqDemandaDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemoverDocumento(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  useEffect(async () => {
    setLoading(true);

    if (data.nrSeqTipoChamada && !data.nrSeqPlanoRateioEmpresa) {
      const planoRateio = await getPlanoRateio(data.nrSeqTipoChamada);
      setHasPlanoRateioDefault(true);

      setData({
        ...data,
        planoRateioEmpresa: planoRateio,
        nrSeqPlanoRateioEmpresa: planoRateio?.nrSeqPlano,
      });
    }

    if (data.documentos) {
      if (data.documentos.length > 0) {
        if (gridViewDocumentos && gridViewDocumentos.current) {
          gridViewDocumentos.current.setDataSource(data.documentos);
        }
      }
    }

    if (data.tituloReceber != null) {
      setTituloReceber(data.tituloReceber);
      setDarEntrada(true);
    }

    setLoading(false);
  }, []);

  const onSearchVencimento = async (e) => {
    const {
      status,
      message: msg,
      vencimentos,
    } = await getVencimentoAutoComplete({
      noVencimento: e,
    });
    onSetMessage(status, msg);
    return vencimentos;
  };

  const onSearchInformacaoBancaria = async () => {
    const {
      status,
      message: msg,
      informacoesBancarias,
    } = await getInformacaoBancariaAutoCompleteFiltraFormaPagamento({
      nrSeqPessoa: data.cliente?.nrSeqPessoaCli,
      nrSeqFormaPagamento: data.nrSeqFormaPagamento,
    });
    onSetMessage(status, msg);
    return informacoesBancarias;
  };

  const onSearchPlanoModeloAparelho = async (e) => {
    const {
      status,
      message: msg,
      value,
    } = await getPlanoModeloAparelhoAutoCompleteForDemanda({
      nrSeqPlano: data.nrSeqPlano,
      noModeloAparelho: e,
      nrSeqTipoChamandaMin: data.nrSeqTipoChamada,
    });

    onSetMessage(status, msg);

    return value;
  };

  const onSearchAparelho = async (e) => {
    const {
      status,
      message: msg,
      aparelhos,
    } = await getAparelhoAutoCompleteForDemanda({
      nrSeqPlano: data.nrSeqPlano,
      nrSeqModeloAparelho: data.nrSeqModeloAparelho,
      nrImei: e,
    });

    onSetMessage(status, msg);
    return aparelhos;
  };

  const onSearchInformacaoBancariaEntrada = async () => {
    const {
      status,
      message: msg,
      informacoesBancarias,
    } = await getInformacaoBancariaAutoCompleteFiltraFormaPagamento({
      nrSeqPessoa: data.cliente?.nrSeqPessoaCli,
      nrSeqFormaPagamento: tituloReceber.nrSeqFormaPagamento,
    });
    onSetMessage(status, msg);
    return informacoesBancarias;
  };

  const onSearchTituloEspecie = async (e) => {
    const {
      status,
      message: msg,
      data: titulos,
    } = await getTituloEspecieAutoComplete({
      noEspecie: e,
    });

    onSetMessage(status, msg);

    return titulos;
  };

  useEffect(() => {
    if (Object.keys(tituloReceber).length > 0) {
      setData({ ...data, tituloReceber });
    } else {
      setData({ ...data, tituloReceber: null });
    }
  }, [tituloReceber]);

  return (
    <>
      <div className='row mb-3'>
        <div className='col'>
          <Autocomplete
            label='Cliente'
            required
            enabled={data.flgPermiteAlterar}
            minLengthTextSearch={3}
            placeholder='Digite ao menos 3 caracteres para buscar Cliente'
            searchDataSource={onSearchCliente}
            selectedItem={data.cliente}
            findTransaction={findTransaction}
            onOpenTransaction={onOpenTransaction}
            transactionToOpen={TransacationCodes.Cliente}
            nrseqTransactionToOpen='nrSeqPessoaCli'
            onSelectItem={(cliente) => {
              data.cliente = cliente;
              data.nrSeqPessoaCli = cliente.nrSeqPessoaCli;
              data.chip = undefined;
              data.nrSeqChip = undefined;
              data.formaPagamento = {};
              data.nrSeqFormaPagamento = undefined;
              data.plano = undefined;
              data.nrSeqPlano = undefined;
              data.tipoChamada = undefined;
              data.nrSeqTipoChamada = undefined;
              data.vlrVenda = undefined;
              data.vlrPacoteEncargoManual = undefined;
              data.centroDeCusto = undefined;
              data.nrSeqCentroDeCusto = undefined;
              data.vencimento = undefined;
              data.nrSeqVencimento = undefined;
              data.operadoraDestino = undefined;
              data.nrSeqOperadoraDestino = undefined;
              data.nrSeqClienteLinhaOriginal = undefined;

              onSelectCliente(cliente, false, true, false);
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Número'
            required
            enabled={data.flgPermiteAlterar}
            searchDataSource={searchClienteLinhas}
            selectedItem={data.chip}
            onSelectItem={async (linha) => {
              setLoading(true);

              if (linha.nrSeqTipoChamada) {
                const planoRateio = await getPlanoRateio(
                  linha.nrSeqTipoChamada
                );

                if (planoRateio) {
                  setHasPlanoRateioDefault(true);

                  data.planoRateioEmpresa = planoRateio;
                  data.nrSeqPlanoRateioEmpresa = planoRateio.nrSeqPlano;
                } else {
                  setHasPlanoRateioDefault(false);

                  data.planoRateioEmpresa = undefined;
                  data.nrSeqPlanoRateioEmpresa = undefined;
                }
              } else {
                setHasPlanoRateioDefault(false);

                data.planoRateioEmpresa = undefined;
                data.nrSeqPlanoRateioEmpresa = undefined;
              }

              if (data.nrSeqPessoaCli) {
                setData({
                  ...data,
                  chip: linha.chip,
                  nrSeqChip: linha.nrSeqChip,
                  formaPagamento: linha.formaPagamento ?? {},
                  nrSeqFormaPagamento: linha.nrSeqFormaPagamento,
                  plano: linha.plano,
                  nrSeqPlano: linha.nrSeqPlano,
                  tipoChamada: linha.tipoChamada,
                  nrSeqTipoChamada: linha.nrSeqTipoChamada,
                  vlrVenda: linha.vlrEncargo,
                  vlrPacoteEncargoManual: linha.vlrEncargo,
                  centroDeCusto: linha.centroDeCusto,
                  nrSeqCentroDeCusto: linha.nrSeqCentroDeCusto,
                  vencimento: linha.vencimento,
                  nrSeqVencimento: linha.nrSeqVencimento,
                  operadoraDestino: linha.chip?.operadora,
                  nrSeqOperadoraDestino: linha.chip?.operadora?.nrSeqOperadora,
                  nrSeqOperadora: linha.chip?.operadora?.nrSeqOperadora,
                  nrSeqClienteLinhaOriginal: linha.nrSeqClienteLinha,
                });
              } else {
                const { clientes } = await getClienteAutoCompleteDemanda({
                  nrSeqPessoa: linha.nrSeqPessoaCli,
                  flgIncluirEndereco: true,
                  flgFiltrarEmpresa: true,
                });

                if (clientes.length > 0) {
                  data.chip = linha.chip;
                  data.nrSeqChip = linha.nrSeqChip;
                  data.formaPagamento = linha.formaPagamento ?? {};
                  data.nrSeqFormaPagamento = linha.nrSeqFormaPagamento;
                  data.plano = linha.plano;
                  data.nrSeqPlano = linha.nrSeqPlano;
                  data.tipoChamada = linha.tipoChamada;
                  data.nrSeqTipoChamada = linha.nrSeqTipoChamada;
                  data.vlrVenda = linha.vlrEncargo;
                  data.vlrPacoteEncargoManual = linha.vlrEncargo;
                  data.centroDeCusto = linha.centroDeCusto;
                  data.nrSeqCentroDeCusto = linha.nrSeqCentroDeCusto;
                  data.vencimento = linha.vencimento;
                  data.nrSeqVencimento = linha.nrSeqVencimento;
                  data.operadoraDestino = linha.chip?.operadora;
                  data.nrSeqOperadoraDestino =
                    linha.chip?.operadora?.nrSeqOperadora;
                  data.nrSeqOperadora = linha.chip?.operadora?.nrSeqOperadora;
                  data.nrSeqClienteLinhaOriginal = linha.nrSeqClienteLinha;

                  await onSelectCliente(clientes[0]);
                } else {
                  onSetMessage(
                    ResponseStatus.Error,
                    'Nenhum cliente valido encontrado para essa linha'
                  );
                }
              }

              setLoading(false);
            }}
            dataSourceTextProperty='linhaFormatada'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-3'>
          <Autocomplete
            label='Operadora'
            enabled={false}
            selectedItem={data.operadoraDestino}
            dataSourceTextProperty='noOperadora'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Centro de Custo'
            enabled={data.flgPermiteAlterar && !!data.empresa?.nrSeqEmpresa}
            placeholder={!data.empresa?.nrSeqEmpresa && 'Seleciona a Empresa'}
            searchDataSource={onSearchCentroDeCusto}
            selectedItem={data.centroDeCusto}
            onSelectItem={(centroDeCusto) =>
              setData({
                ...data,
                centroDeCusto,
                nrSeqCentroDeCusto: centroDeCusto.nrSeqCentroDeCusto,
              })
            }
            dataSourceTextProperty='noCentroDeCusto'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Plano'
            enabled={data.flgPermiteAlterar && !!data.empresa?.nrSeqEmpresa}
            required
            searchDataSource={onSearchPlanoOperadoraDestivo}
            selectedItem={data.plano}
            onSelectItem={(plano) =>
              setData({
                ...data,
                plano,
                nrSeqPlano: plano.nrSeqPlano,
                tipoChamada: {},
                nrSeqTipoChamada: undefined,
              })
            }
            dataSourceTextProperty='noPlano'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <Autocomplete
            label='Pacote'
            enabled={data.flgPermiteAlterar}
            required
            searchDataSource={onSearchPacoteDados}
            selectedItem={data.tipoChamada}
            onSelectItem={async (tipoChamada) => {
              if ('nrSeqTipoChamada' in tipoChamada) {
                const planoRateio = await getPlanoRateio(
                  tipoChamada.nrSeqTipoChamada
                );

                if (planoRateio) {
                  setHasPlanoRateioDefault(true);

                  setData({
                    ...data,
                    tipoChamada,
                    nrSeqTipoChamada: tipoChamada.nrSeqTipoChamada,
                    nrSeqTipoChamadaDados: tipoChamada.nrSeqTipoChamada,
                    vlrVenda: tipoChamada.vlrVenda ?? tipoChamada.vlrEncargo,
                    vlrPacoteEncargoManual:
                      tipoChamada.vlrVenda ?? tipoChamada.vlrEncargo,
                    planoRateioEmpresa: planoRateio,
                    nrSeqPlanoRateioEmpresa: planoRateio.nrSeqPlano,
                    planoModeloAparelho: undefined,
                    nrSeqModeloAparelho: undefined,
                    parcelamentoAparelho: undefined,
                    aparelho: undefined,
                    nrSeqAparelho: undefined,
                  });
                } else {
                  setHasPlanoRateioDefault(false);

                  setData({
                    ...data,
                    tipoChamada,
                    nrSeqTipoChamada: tipoChamada.nrSeqTipoChamada,
                    nrSeqTipoChamadaDados: tipoChamada.nrSeqTipoChamada,
                    vlrVenda: tipoChamada.vlrVenda ?? tipoChamada.vlrEncargo,
                    vlrPacoteEncargoManual:
                      tipoChamada.vlrVenda ?? tipoChamada.vlrEncargo,
                    planoRateioEmpresa: undefined,
                    nrSeqPlanoRateioEmpresa: undefined,
                    planoModeloAparelho: undefined,
                    nrSeqModeloAparelho: undefined,
                    parcelamentoAparelho: undefined,
                    aparelho: undefined,
                    nrSeqAparelho: undefined,
                  });
                }
              } else {
                setHasPlanoRateioDefault(false);

                setData({
                  ...data,
                  tipoChamada,
                  nrSeqTipoChamada: undefined,
                  nrSeqTipoChamadaDados: undefined,
                  vlrVenda: undefined,
                  vlrPacoteEncargoManual: undefined,
                  planoRateioEmpresa: undefined,
                  nrSeqPlanoRateioEmpresa: undefined,
                });
              }

              setDarEntrada(false);
            }}
            dataSourceTextProperty='noTipoChamada'
          />
        </div>
        <div className='col-1'>
          <Textbox
            label='Valor Pacote'
            text={data.vlrVenda}
            mask={MaskTypes.Decimal}
            readOnly={
              !data.nrSeqTipoChamada ||
              (!data.tipoChamada?.flgPermiteEditar && data.vlrVenda > 0)
            }
            onChangedValue={(vlrVenda) => {
              setData({
                ...data,
                vlrVenda,
                vlrPacoteEncargoManual: vlrVenda,
              });
            }}
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Plano de Rateio Empresa'
            searchDataSource={onSearchPlanoRateio}
            enabled={
              data.flgPermiteAlterar &&
              !!data.empresa?.nrSeqEmpresa &&
              !hasPlanoRateioDefault
            }
            placeholder={!data.empresa?.nrSeqEmpresa && 'Seleciona a Empresa'}
            selectedItem={data.planoRateioEmpresa}
            onSelectItem={(planoRateioEmpresa) => {
              setData({
                ...data,
                planoRateioEmpresa,
                nrSeqPlanoRateioEmpresa: planoRateioEmpresa.nrSeqPlano,
              });
            }}
            dataSourceTextProperty='noPlano'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Modelos de Aparelhos em Estoque'
            enabled={data.flgPermiteAlterar}
            visible={data.plano?.nrSeqPlano > 0}
            searchDataSource={onSearchPlanoModeloAparelho}
            selectedItem={data.planoModeloAparelho}
            onSelectItem={(planoModeloAparelho) => {
              setData({
                ...data,
                planoModeloAparelho,
                nrSeqModeloAparelho: planoModeloAparelho.nrSeqModeloAparelho,
                parcelamentoAparelho: {
                  nrParcelas: planoModeloAparelho.nrParcelas,
                  vlrOriginal: planoModeloAparelho.vlrTotal,
                },
                aparelho: undefined,
                nrSeqAparelho: undefined,
              });

              setDarEntrada(false);
              setTituloReceber({});
            }}
            dataSourceTextProperty='noModeloAparelho'
          />
        </div>
      </div>
      {data.planoModeloAparelho?.nrSeqModeloAparelho != null && (
        <div className='row mb-3'>
          <Panel>
            <Panel.Header title='Dados Aparelho' theme={Theme.Primary} />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col'>
                  <Autocomplete
                    label='IMEI do Aparelho'
                    enabled={data.flgPermiteAlterar}
                    required={!!data.nrSeqModeloAparelho}
                    visible={
                      data.planoModeloAparelho?.nrSeqModeloAparelho != null
                    }
                    searchDataSource={onSearchAparelho}
                    selectedItem={data.aparelho}
                    onSelectItem={(aparelho) =>
                      setData({
                        ...data,
                        aparelho,
                        nrSeqAparelho: aparelho.nrSeqAparelho,
                      })
                    }
                    dataSourceTextProperty='nrImei'
                  />
                </div>
                <div className='col-1'>
                  <Textbox
                    label='Parcelas'
                    required={!!data.nrSeqModeloAparelho}
                    readOnly={
                      !data.flgPermiteAlterar &&
                      !data.parcelamentoAparelho?.flgAutorizaEditar
                    }
                    text={data.parcelamentoAparelho?.nrParcelas}
                    mask={MaskTypes.Integer}
                    onChangedValue={(nrParcelas) =>
                      setData({
                        ...data,
                        parcelamentoAparelho: {
                          ...data.parcelamentoAparelho,
                          nrParcelas,
                        },
                      })
                    }
                  />
                </div>
                <div className='col'>
                  <Textbox
                    label='Valor Aparelho'
                    readOnly
                    text={data.parcelamentoAparelho?.vlrOriginal}
                    mask={MaskTypes.Currency}
                  />
                </div>
                <div className='col'>
                  <Textbox
                    label='Valor Parcela'
                    readOnly
                    text={
                      darEntrada && tituloReceber.vlrTitulo
                        ? (data.parcelamentoAparelho?.vlrOriginal -
                            tituloReceber.vlrTitulo) /
                          data.parcelamentoAparelho?.nrParcelas
                        : data.parcelamentoAparelho?.vlrOriginal /
                          data.parcelamentoAparelho?.nrParcelas
                    }
                    mask={MaskTypes.Currency}
                  />
                </div>
                <div className='col-2'>
                  <Switch
                    formControl
                    label='Dar Entrada'
                    checked={darEntrada}
                    onChange={(value) => {
                      setDarEntrada(value);
                      setTituloReceber({});
                    }}
                  />
                </div>
              </div>
              {darEntrada && (
                <div className='row mb-3'>
                  <div className='col'>
                    <Textbox
                      label='Valor da Entrada'
                      required={darEntrada}
                      text={tituloReceber.vlrTitulo ?? 0}
                      onChangedValue={(vlrTitulo) =>
                        setTituloReceber({
                          ...tituloReceber,
                          vlrTitulo,
                        })
                      }
                      mask={MaskTypes.Decimal}
                    />
                  </div>
                  <div className='col-2'>
                    <DatePicker
                      label='Vencimento da Entrada'
                      required={darEntrada}
                      text={tituloReceber.dtVencimento}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dtVencimento) =>
                        setTituloReceber({
                          ...tituloReceber,
                          dtVencimento,
                        })
                      }
                    />
                  </div>
                  <div className='col'>
                    <Autocomplete
                      label='Tipo Receita'
                      required={darEntrada}
                      searchDataSource={onSearchTituloEspecie}
                      selectedItem={tituloReceber.tituloEspecie}
                      onSelectItem={(tituloEspecie) =>
                        setTituloReceber({
                          ...tituloReceber,
                          tituloEspecie,
                          nrSeqTituloEspecie: tituloEspecie.nrSeqTituloEspecie,
                        })
                      }
                      dataSourceTextProperty='noEspecie'
                    />
                  </div>
                  <div className='col'>
                    <Autocomplete
                      label='Forma Pagamento da Entrada'
                      required={darEntrada}
                      searchDataSource={onAutoCompleteFormaPagamento}
                      selectedItem={tituloReceber.formaPagamento}
                      onSelectItem={(formaPagamento) =>
                        setTituloReceber({
                          ...tituloReceber,
                          formaPagamento,
                          nrSeqFormaPagamento:
                            formaPagamento.nrSeqFormaPagamento,
                        })
                      }
                      dataSourceTextProperty='noFormaPagamento'
                    />
                  </div>
                  <div className='col'>
                    <Autocomplete
                      label='Informação Bancária da Entrada'
                      required={tituloReceber.formaPagamento?.flgGrupo === 'D'}
                      visible={
                        tituloReceber.formaPagamento !== undefined &&
                        tituloReceber.formaPagamento?.flgGrupo === 'D'
                      }
                      searchDataSource={onSearchInformacaoBancariaEntrada}
                      selectedItem={tituloReceber.informacaoBancaria}
                      onSelectItem={(informacaoBancaria) =>
                        setTituloReceber({
                          ...tituloReceber,
                          informacaoBancaria,
                          nrSeqPessoaInformacaoBancaria:
                            informacaoBancaria.nrSeqPessoaInformacaoBancaria,
                        })
                      }
                      dataSourceTextProperty='noDescricao'
                    />
                  </div>
                </div>
              )}
            </Panel.Body>
          </Panel>
        </div>
      )}
      <div className='row mb-3'>
        <div className='col'>
          <Autocomplete
            label='Forma de Pagamento'
            enabled={data.flgPermiteAlterar}
            required
            searchDataSource={onAutoCompleteFormaPagamento}
            selectedItem={data.formaPagamento}
            onSelectItem={(formaPagamento) => {
              data.formaPagamento = formaPagamento;
              data.nrSeqFormaPagamento = formaPagamento.nrSeqFormaPagamento;

              onSelectFormaPagamento(formaPagamento);
            }}
            dataSourceTextProperty='noFormaPagamento'
          />
        </div>
        <div className='col-2'>
          <Autocomplete
            label='Vencimento'
            enabled={data.flgPermiteAlterar}
            required
            searchDataSource={onSearchVencimento}
            selectedItem={data.vencimento}
            onSelectItem={(vencimento) =>
              setData({
                ...data,
                vencimento,
                nrSeqVencimento: vencimento.nrSeqVencimento,
              })
            }
            dataSourceTextProperty='noVencimento'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Informação Bancária'
            enabled={data.flgPermiteAlterar}
            visible={
              data.nrSeqFormaPagamento !== null &&
              data.nrSeqFormaPagamento !== undefined &&
              data.formaPagamento?.flgGrupo === 'D'
            }
            required={data.formaPagamento?.flgGrupo === 'D'}
            searchDataSource={onSearchInformacaoBancaria}
            selectedItem={data.informacaoBancaria}
            onSelectItem={(informacaoBancaria) =>
              setData({
                ...data,
                informacaoBancaria,
                nrSeqPessoaInformacaoBancaria:
                  informacaoBancaria.nrSeqPessoaInformacaoBancaria,
              })
            }
            dataSourceTextProperty='noDescricao'
          />
        </div>
      </div>

      <div className='row mt-4 mb-3'>
        <div className='col-6'>
          <FileUpload
            files={importedFile}
            onChange={(files) => {
              if (files.length > 0) {
                if (files[0].size <= 5000000) {
                  setImportedFile(files);
                } else {
                  onSetMessage(false, 'Arquivo não pode ser maior que 5MB');
                }
              } else {
                setImportedFile(files);
              }
            }}
            allowedMimeTypes={[
              MimeTypes.Types.PDF,
              MimeTypes.Types.Image,
              MimeTypes.Types.Word,
            ]}
          />
        </div>
        <div className='col d-flex justify-content-center'>
          <Button
            text='Adicionar Documento'
            className='px-5'
            theme={Theme.Success}
            size={BootstrapSizes.Medium}
            template={Button.Templates.Button}
            onClick={() => onClickAddDocumento()}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridViewDocumentos}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columnsDocumentos}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
      </div>
    </>
  );
}
