import React, { useRef, useEffect, useState, useCallback } from 'react';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import {
  BootstrapSizes,
  MimeTypes,
  PageTypes,
  toBase64,
} from 'ui/Helpers/utils';
import {
  Autocomplete,
  Button,
  CSDManutPage,
  FileUpload,
  GridView,
  Panel,
  RadioButton,
  Switch,
  Textbox,
} from 'ui/components';

import {
  getControleMercadoria,
  saveControleMercadoria,
  deleteControleMercadoria,
  generateTravel,
  generateTravelFinancial,
  generateTravelUnique,
  removeDelivery,
  removeArrivalOfMaterials,
  releaseForCounting,
  excelCheckCountingReport,
  printCheckMaterialsReport,
  printDeliveryReport,
} from 'core/services/FRO/controleMercadoria';

import {
  printColetaEmbarque,
  transmitCte,
  downloadCte,
} from 'core/services/FRO/coletaEmbarque';

import { transmitirMultiplosCtes } from 'core/services/FRO/viagem';

import { getSearchPendingControlMerchandiseList } from 'core/services/COP/nf';

import { getMotoristaViagemAutoComplete } from 'core/services/FRO/motorista';
import { getClienteAutoComplete } from 'core/services/FIN/cliente';

import {
  getVeiculoAutoComplete,
  getVeiculoPorVinculoAutoComplete,
} from 'core/services/FRO/veiculo';

import { getEmpresaAutoComplete } from 'core/services/SEG/empresa';
import { getTipoEmbarqueAutoComplete } from 'core/services/FRO/tipoEmbarque';

import { getAlmoxarifadoAutoComplete } from 'core/services/EST/almoxarifado';
// @ts-expect-error
import { Message, Page } from 'ui/Helpers/interfaces.ts';
// @ts-expect-error
import { ColumnTypes, ResponseStatus, Theme } from 'ui/Helpers/enums.ts';
import ControleMercadoria from 'core/models/FRO/controleMercadoria';
import { Empresa } from 'core/models/SEG';
import { Almoxarifado } from 'core/models/EST';
import Veiculo from 'core/models/FRO/veiculo';
import { Motorista } from 'core/models/FRO';
import { Cliente } from 'core/models/FIN';
import { NfControleMercadoriaModel } from 'core/models/FRO/models';

export default function ControleEmbarqueMercadoriastem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
  findTransaction,
  onOpenTransaction,
  onOpenReport,
}: Page) {
  interface filtroNotas {
    noFornecedor: string;
    nrFornNf: string;
    noCidadeDestino: string;
    noUfDestino: string;
  }

  const formSubmit = useRef<any>();

  const [data, setData] = useState<ControleMercadoria | {}>({});
  const [filtros, setFiltros] = useState<filtroNotas | {}>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);
  const [importedFile, setImportedFile] = useState([]);

  const [key, setKey] = useState('');

  const gridViewDocumentosEmbarque = useRef<any>();
  const gridViewDocumentosEmbarqueVincularNotas = useRef<any>();
  const gridViewValidacaoContagemProdutos = useRef<any>();

  const gridViewDocumentosDesembarque = useRef<any>();

  const style = {
    id: {
      fontWeight: 'bold',
      fontSize: '25px',
      textAlign: 'center',
      backgroundColor: 'white',
      border: '1px solid #80808052',
      padding: '20px',
    },
  };

  const onSetMessage = (status: ResponseStatus, msg: string) => {
    let theme;

    if (status === ResponseStatus.Success) theme = Theme.Success;
    else if (status === ResponseStatus.Warning) theme = Theme.Warning;
    else theme = Theme.Danger;

    if (msg)
      setMessage({
        message: msg,
        theme,
      });
  };

  const onClickOpenNf = async (sourceRow: any) => {
    const { nrSeqNf } = sourceRow;

    const transactionNotaEntrada = findTransaction(
      TransacationCodes.NotaFiscalEntrada
    );

    if (transactionNotaEntrada) {
      transactionNotaEntrada.registryKey = nrSeqNf;
      onOpenTransaction(transactionNotaEntrada, true, undefined, true);
    }
  };

  const onClickOpenViagem = async (e: any) => {
    const transactionViagem = findTransaction(TransacationCodes.Viagem);

    if (transactionViagem) {
      transactionViagem.registryKey = e.nrSeqViagem;
      onOpenTransaction(transactionViagem, true, undefined, true);
    }
  };

  const onClickDownload = async (e: any) => {
    setLoading(true);

    const { data: xmls, status, message: msg } = await downloadCte({
      nrSeqColetaEmbarque: e.nrSeqColetaEmbarque,
    });

    if (status !== ResponseStatus.Success) {
      onSetMessage(status, msg);
    } else if (xmls) {
      for (let i = 0; i < xmls.length; i += 1) {
        const xmlURI = encodeURIComponent(xmls[i].xml);
        const downloadLink = document.createElement('a');
        downloadLink.href = `data:text/plain;charset=utf-8,${xmlURI}`;
        downloadLink.download = `CTe_${e.cdColetaEmbarque}.xml`;
        downloadLink.download = `${xmls[i].nome}_${e.cdColetaEmbarque}.xml`;
        downloadLink.click();
      }
    }

    setLoading(false);
  };

  const onClickPrintCheckMaterialsReport = async () => {
    setLoading(true);

    const nrSeqControleMercadoria =
      'nrSeqControleMercadoria' in data ? data.nrSeqControleMercadoria : 0;
    const { status, message: msg, value } = await printCheckMaterialsReport({
      nrSeqControleMercadoria,
    });

    if (status === ResponseStatus.Success)
      onOpenReport(transaction.noTransacao, value);
    else if (msg) onSetMessage(status, msg);

    setLoading(false);
  };

  const onClickPrintDeliveryReport = async () => {
    setLoading(true);

    const nrSeqControleMercadoria =
      'nrSeqControleMercadoria' in data ? data.nrSeqControleMercadoria : 0;
    const { status, message: msg, value } = await printDeliveryReport({
      nrSeqControleMercadoria,
    });

    if (status === ResponseStatus.Success)
      onOpenReport(transaction.noTransacao, value);
    else if (msg) onSetMessage(status, msg);

    setLoading(false);
  };
  const onClickImprimirCte = async (e: any) => {
    setLoading(true);

    const { nrSeqColetaEmbarque } = e;
    const { status, message: msg, value } = await printColetaEmbarque({
      nrSeqColetaEmbarque,
    });

    if (status === ResponseStatus.Success)
      onOpenReport(transaction.noTransacao, value);
    else if (msg) onSetMessage(status, msg);

    setLoading(false);
  };

  const onClickTrasmiteCte = async (e: any) => {
    setLoading(true);
    const { nrSeqColetaEmbarque } = e;
    const { status, messages: msgs } = await transmitCte({
      nrSeqColetaEmbarque,
    });

    if (msgs) onSetMessage(status, msgs[0].message);

    setLoading(false);
  };

  const onAutoCompleteTipoEmbarque = async (e, nrSeqEmpresa) => {
    const {
      status,
      message: msg,
      data: tiposEmbarque,
    } = await getTipoEmbarqueAutoComplete({
      noTipoEmbarque: e,
      nrSeqEmpresa: nrSeqEmpresa ?? data.nrSeqEmpresa,
    });

    if (msg) onSetMessage(status, msg);

    return tiposEmbarque;
  };

  const saveOrderEmbarque = async (keyValue: any, value: any) => {
    const datasource = gridViewDocumentosEmbarque.current
      ? gridViewDocumentosEmbarque.current.getDataSource()
      : [];

    datasource.find((item: any) => item.nrSeqNf === keyValue).nrOrdem = value;

    if (gridViewDocumentosEmbarque && gridViewDocumentosEmbarque.current)
      gridViewDocumentosEmbarque.current.setDataSource(datasource);
  };

  const saveObsEmbarque = async (keyValue: any, value: any) => {
    const datasource = gridViewDocumentosEmbarque.current
      ? gridViewDocumentosEmbarque.current.getDataSource()
      : [];

    datasource.find(
      (item: any) => item.nrSeqNf === keyValue
    ).noObservacoes = value;

    if (gridViewDocumentosEmbarque && gridViewDocumentosEmbarque.current)
      gridViewDocumentosEmbarque.current.setDataSource(datasource);
  };

  const saveOrderDesembarque = async (keyValue: any, value: any) => {
    const datasource = gridViewDocumentosDesembarque.current
      ? gridViewDocumentosDesembarque.current.getDataSource()
      : [];

    datasource.find((item: any) => item.nrSeqNf === keyValue).nrOrdem = value;

    if (gridViewDocumentosDesembarque && gridViewDocumentosDesembarque.current)
      gridViewDocumentosDesembarque.current.setDataSource(datasource);
  };

  const saveObsDesembarque = async (keyValue: any, value: any) => {
    const datasource = gridViewDocumentosDesembarque.current
      ? gridViewDocumentosDesembarque.current.getDataSource()
      : [];

    datasource.find(
      (item: any) => item.nrSeqNf === keyValue
    ).noObservacoes = value;

    if (gridViewDocumentosDesembarque && gridViewDocumentosDesembarque.current)
      gridViewDocumentosDesembarque.current.setDataSource(datasource);
  };

  const saveOrder = async (keyValue: any, value: any) => {
    const notipo = 'noTipo' in data ? data.noTipo ?? null : '';

    if (notipo === 'D') saveOrderDesembarque(keyValue, value);
    else saveOrderEmbarque(keyValue, value);
  };

  const saveObs = async (keyValue: any, value: any) => {
    const notipo = 'noTipo' in data ? data.noTipo ?? null : '';
    if (notipo === 'D') saveObsDesembarque(keyValue, value);
    else saveObsEmbarque(keyValue, value);
  };

  const columns = [
    {
      key: 'nrSeqNf',
      type: 2,
      sortable: true,
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'nrSeqColetaEmbarque',
      visible: false,
    },
    {
      key: 'fornecedor.noPessoa',
      title: 'Remetente',
    },
    {
      key: 'destinatario.noPessoa',
      title: 'Destinatário',
    },

    {
      key: 'cidadeEntrega.noCidade',
      title: 'Cidade Entrega',
    },

    {
      key: 'cidadeEntrega.estado.cdEstado',
      title: 'UF Entrega',
    },

    { key: 'nrForNf', title: 'Número' },

    {
      key: 'flgReentrega',
      title: 'Reentrega',
      format: GridView.DataTypes.Boolean,
    },

    { key: 'nrSeqViagem', title: 'Viagem' },

    { key: 'cdColetaEmbarque', title: 'CTE' },

    { key: 'averbado', title: 'Averbado', color: 'noColorAverbado' },
    {
      key: 'statusCte',
      title: 'status',
      color: 'noColorStatus',
    },
    {
      key: 'nrOrdem',
      title: 'Ordem',
      type: GridView.ColumnTypes.Textbox,

      format: GridView.DataTypes.DecimalCustom,

      onBlur: saveOrder,
      maxLength: 10,
    },

    {
      key: 'noObservacoes',
      title: 'Obs',
      type: GridView.ColumnTypes.Textbox,

      format: GridView.DataTypes.DecimalCustom,

      onBlur: saveObs,
      maxLength: 100,
    },

    {
      key: 'nrSeqNf',
      title: 'Nf',
      type: ColumnTypes.Button,
      onClick: (e: any) => onClickOpenNf(e),
      theme: Theme.Success,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Abrir',
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'Env',
      type: GridView.ColumnTypes.Button,
      visibleDynamic: 'flgMostraTransmitir',
      onClick: (e: any) => onClickTrasmiteCte(e),
      theme: Theme.Success,
      icon: 'share',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Transmitir',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'Imp',
      type: GridView.ColumnTypes.Button,
      onClick: (e: any) => onClickImprimirCte(e),
      theme: Theme.Info,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      visibleDynamic: 'flgMostraDownload',
      title: 'Dow',
      type: GridView.ColumnTypes.Button,
      onClick: (e: any) => onClickDownload(e),
      theme: Theme.Info,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqViagem',
      title: 'Viag',
      type: GridView.ColumnTypes.Button,
      onClick: (e: any) => onClickOpenViagem(e),
      theme: Theme.Danger,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Viagem',
      tooltipDirection: 'bottom',
    },
  ];

  const columnsVincularNotas = [
    {
      key: 'nrSeqNf',
      type: 2,
      sortable: true,
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'nrSeqColetaEmbarque',
      visible: false,
    },
    {
      key: 'fornecedor.noPessoa',
      title: 'Remetente',
    },
    {
      key: 'destinatario.noPessoa',
      title: 'Destinatário',
    },

    {
      key: 'cidadeEntrega.noCidade',
      title: 'Cidade Entrega',
    },

    {
      key: 'cidadeEntrega.estado.cdEstado',
      title: 'UF Entrega',
    },

    { key: 'nrForNf', title: 'Número' },

    { key: 'nrSeqViagem', title: 'Viagem' },

    { key: 'cdColetaEmbarque', title: 'CTE' },
  ];

  const columnsContagemMercadorias = [
    {
      key: 'nrSeqPessoaFor',
      visible: false,
    },
    {
      key: 'noFornecedor',
      title: 'Fornecedor',
    },
    {
      key: 'nrSeqNf',
      title: 'nrSeqNf',
      visible: false,
    },

    {
      key: 'nrForNf',
      title: 'Número',
    },

    {
      key: 'nrSeqProduto',
      title: 'nrSeqProduto',
      visible: false,
    },

    { key: 'cdPeca', title: 'cdPeca' },
    { key: 'noProduto', title: 'Produto' },

    { key: 'noEan', title: 'EAN' },

    { key: 'qtdeItNf', title: 'Quant.Nota', format: 2 },

    { key: 'nrQuantidade', title: 'Contagem', format: 2 },

    { key: 'nrQuantidadeDiferenca', title: 'Diferença', format: 2 },
  ];

  const tipo = [
    {
      text: 'Desembarque',
      value: 'D',
    },
    {
      text: 'Embarque',
      value: 'E',
    },
  ];

  const load = useCallback(
    async (nrseqcontrolemercadoria: number | undefined): Promise<void> => {
      let id;

      if (nrseqcontrolemercadoria) id = nrseqcontrolemercadoria;

      if (!id && !isNaN(registryKey)) {
        id = registryKey;
      }

      if (id) {
        setLoading(true);

        const value = await getControleMercadoria(id);
        if (value.noTipo === 'D') {
          if (
            gridViewDocumentosDesembarque &&
            gridViewDocumentosDesembarque.current
          )
            gridViewDocumentosDesembarque.current.setDataSource(
              value.notasVinculadas
            );
        } else if (value.noTipo === 'E') {
          if (gridViewDocumentosEmbarque && gridViewDocumentosEmbarque.current)
            gridViewDocumentosEmbarque.current.setDataSource(
              value.notasVinculadas
            );
        }

        if (
          gridViewDocumentosEmbarqueVincularNotas &&
          gridViewDocumentosEmbarqueVincularNotas.current
        )
          gridViewDocumentosEmbarqueVincularNotas.current.setDataSource([]);

        if (
          gridViewValidacaoContagemProdutos &&
          gridViewValidacaoContagemProdutos.current
        )
          gridViewValidacaoContagemProdutos.current.setDataSource(
            value.contagem
          );

        setData(value);
        setLoading(false);
      } else {
        setData({ noTipo: 'D' });
        if (
          gridViewDocumentosDesembarque &&
          gridViewDocumentosDesembarque.current
        )
          gridViewDocumentosDesembarque.current.setDataSource([]);

        if (gridViewDocumentosEmbarque && gridViewDocumentosEmbarque.current)
          gridViewDocumentosEmbarque.current.setDataSource([]);

        if (
          gridViewDocumentosEmbarqueVincularNotas &&
          gridViewDocumentosEmbarqueVincularNotas.current
        )
          gridViewDocumentosEmbarqueVincularNotas.current.setDataSource([]);

        if (
          gridViewValidacaoContagemProdutos &&
          gridViewValidacaoContagemProdutos.current
        )
          gridViewValidacaoContagemProdutos.current.setDataSource([]);
      }
      setMessage(null);
    },
    [registryKey]
  );

  useEffect(() => {
    (async function func(): Promise<void> {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func(): Promise<void> {
      await load();
    })();
  }, [load, reload]);

  useEffect(() => {}, [importedFile]);

  const onAutoCompleteVeiculo = async (e) => {
    const { status, message: msg, veiculos } = await getVeiculoAutoComplete({
      placa: e,
    });
    if (msg) onSetMessage(status, msg);
    return veiculos;
  };

  const onAutoCompleteVeiculoCompAB = async (e) => {
    const {
      status,
      message: msg,
      veiculos,
    } = await getVeiculoPorVinculoAutoComplete({
      placa: e,
      nrSeqVeiculo: 'nrSeqVeiculo' in data ? data.nrSeqVeiculo ?? null : '',
    });
    if (msg) onSetMessage(status, msg);
    return veiculos;
  };

  const onAutoCompleteMotorista = async (e) => {
    const {
      status,
      message: msg,
      motoristas,
    } = await getMotoristaViagemAutoComplete({
      noPessoa: e,
      nrSeqVeiculo: 'nrSeqVeiculo' in data ? data.nrSeqVeiculo ?? null : null,
    });
    if (msg) onSetMessage(status, msg);
    return motoristas;
  };

  const save = async (
    notasImportar: Array<string> | undefined,
    notasVincular: Array<NfControleMercadoriaModel> | undefined
  ): Promise<{ status: number; nrSeqControleMercadoria: number }> => {
    setLoading(true);
    const { status, message: msg, value } = await saveControleMercadoria({
      ...data,
      notasImportar,
      notasVincular,
    });

    if (status === ResponseStatus.Success) {
      setData(value);
      setImportedFile([]);
      if (value.noTipo === 'D') {
        if (
          gridViewDocumentosDesembarque &&
          gridViewDocumentosDesembarque.current
        )
          gridViewDocumentosDesembarque.current.setDataSource(
            value.notasVinculadas
          );
      } else if (value.noTipo === 'E') {
        if (gridViewDocumentosEmbarque && gridViewDocumentosEmbarque.current)
          gridViewDocumentosEmbarque.current.setDataSource(
            value.notasVinculadas
          );
      }
    }

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);

    const nrSeqControleMercadoria =
      'nrSeqControleMercadoria' in value ? value.nrSeqControleMercadoria : 0;
    return { status, nrSeqControleMercadoria };
  };

  const onExcluir = async (): Promise<void> => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteControleMercadoria(
        registryKey
      );

      setData(status === ResponseStatus.Success ? { noTipo: 'E' } : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onReadDocumentos = async () => {
    let result: string[] = [];
    result = await Promise.all(
      importedFile.map(async (item) => {
        const value: string = await toBase64(item);
        return value.replace('data:text/xml;base64,', '');
      })
    );

    return result;
  };

  const onAutoCompleteEmpresa = async (e: string) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noPessoa: e,
    });

    if (msg) onSetMessage(status, msg);

    return empresas;
  };

  const onSearchCliente = async (e) => {
    const { clientes } = await getClienteAutoComplete({
      noPessoa: e,
      flgFiltrarEmpresa: true,
    });
    return clientes;
  };

  const onSearchNotasNaoVinculadas = async () => {
    const {
      status,
      message: msg,
      data: notas,
    } = await getSearchPendingControlMerchandiseList({
      noFornecedor: filtros.noFornecedor,
      nrForNf: filtros.nrForNf,
      noCidadeDestino: filtros.noCidadeDestino,
      noUfDestino: filtros.noUfDestino,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });

    if (msg) onSetMessage(status, msg);
    if (
      gridViewDocumentosEmbarqueVincularNotas &&
      gridViewDocumentosEmbarqueVincularNotas.current
    )
      gridViewDocumentosEmbarqueVincularNotas.current.setDataSource(notas);
  };

  const onAddNotasEmbarque = async () => {
    if (
      gridViewDocumentosEmbarqueVincularNotas &&
      gridViewDocumentosEmbarqueVincularNotas.current
    ) {
      const selecteds = gridViewDocumentosEmbarqueVincularNotas.current.getCheckBoxValuesAt(
        0
      );

      if (selecteds.length === 0) {
        onSetMessage(0, 'Necessário selecionar as notas para inclusão');
      }

      const { status, nrSeqControleMercadoria } = await save(
        undefined,
        selecteds.map((row: any) => {
          const item: NfControleMercadoriaModel = Object.create(
            NfControleMercadoriaModel.prototype
          );

          item.nrSeqNf = row[0].value;

          return item;
        })
      );
      if (status === ResponseStatus.Success) {
        await load(nrSeqControleMercadoria);
      }

      if (status === ResponseStatus.Success) {
        await onSearchNotasNaoVinculadas();
      }
    }
  };

  const onGenerateTravel = async () => {
    const nrseqcontrolemercadoria =
      'nrSeqControleMercadoria' in data ? data.nrSeqControleMercadoria : 0;

    const { status, message: msg } = await generateTravel(
      nrseqcontrolemercadoria
    );

    if (status === ResponseStatus.Success) {
      await load(nrseqcontrolemercadoria);
    }
    if (msg) onSetMessage(status, msg);
  };

  const onGenerateTravelUnique = async () => {
    const selecteds = gridViewDocumentosEmbarque.current.getCheckBoxValuesAt(0);

    if (selecteds.length === 0) {
      onSetMessage(
        0,
        'Necessário selecionar as notas para geração do cte único'
      );

      return;
    }

    const nrseqcontrolemercadoria =
      'nrSeqControleMercadoria' in data ? data.nrSeqControleMercadoria : 0;

    const { status, message: msg } = await generateTravelUnique(
      nrseqcontrolemercadoria,
      selecteds.map((row: any) => row[0].value)
    );

    if (status === ResponseStatus.Success) {
      await load(nrseqcontrolemercadoria);
    }
    if (msg) onSetMessage(status, msg);
  };

  const transmitirCtesSelecionados = async () => {
    const selecteds = gridViewDocumentosEmbarque.current
      .getCheckBoxValuesAt(0)
      .map((row: any) => row[1].value);

    if (selecteds.length <= 0) {
      onSetMessage(1, 'Necessário selecionar pelo menos um conhecimento.');

      return;
    }

    setLoading(true);

    await transmitirMultiplosCtes({
      nrSeqsColetaEmbarque: selecteds,
    });

    const nrseqcontrolemercadoria =
      'nrSeqControleMercadoria' in data ? data.nrSeqControleMercadoria : 0;

    await load(nrseqcontrolemercadoria);

    onSetMessage(
      ResponseStatus.Success,
      'Ctes transmitidos, confirme o status de transmissão de cada cte.'
    );

    setLoading(false);
  };

  const transmitirCtesSelecionadosDesembarque = async () => {
    const selecteds = gridViewDocumentosDesembarque.current
      .getCheckBoxValuesAt(0)
      .map((row: any) => row[1].value);

    if (selecteds.length <= 0) {
      onSetMessage(1, 'Necessário selecionar pelo menos um conhecimento.');

      return;
    }

    setLoading(true);

    await transmitirMultiplosCtes({
      nrSeqsColetaEmbarque: selecteds,
    });

    const nrseqcontrolemercadoria =
      'nrSeqControleMercadoria' in data ? data.nrSeqControleMercadoria : 0;

    await load(nrseqcontrolemercadoria);

    onSetMessage(
      ResponseStatus.Success,
      'Ctes transmitidos, confirme o status de transmissão de cada cte.'
    );

    setLoading(false);
  };

  const onGenerateTravelFinancial = async () => {
    const nrseqcontrolemercadoria =
      'nrSeqControleMercadoria' in data ? data.nrSeqControleMercadoria : 0;

    const { status, message: msg } = await generateTravelFinancial(
      nrseqcontrolemercadoria
    );
    if (status === ResponseStatus.Success) {
      await load(nrseqcontrolemercadoria);
    }

    if (msg) onSetMessage(status, msg);
  };

  const onRemoveDelivery = async () => {
    const selecteds = gridViewDocumentosEmbarque.current.getCheckBoxValuesAt(0);

    if (selecteds.length === 0) {
      onSetMessage(0, 'Necessário selecionar as notas para remoção');

      return;
    }

    const nrseqcontrolemercadoria =
      'nrSeqControleMercadoria' in data ? data.nrSeqControleMercadoria : 0;

    const { status, message: msg } = await removeDelivery(
      selecteds.map((row: any) => row[0].value),
      'nrSeqControleMercadoria' in data ? data.nrSeqControleMercadoria : 0
    );

    if (status === ResponseStatus.Success) {
      await load(nrseqcontrolemercadoria);
    }
    if (msg) onSetMessage(status, msg);
  };

  const onRemoveArrivalOfMaterials = async () => {
    const selecteds = gridViewDocumentosDesembarque.current.getCheckBoxValuesAt(
      0
    );

    if (selecteds.length === 0) {
      onSetMessage(0, 'Necessário selecionar as notas para remoção');

      return;
    }

    const nrseqcontrolemercadoria =
      'nrSeqControleMercadoria' in data ? data.nrSeqControleMercadoria : 0;

    const { status, message: msg } = await removeArrivalOfMaterials(
      selecteds.map((row: any) => row[0].value)
    );

    if (status === ResponseStatus.Success) {
      await load(nrseqcontrolemercadoria);
    }
    if (msg) onSetMessage(status, msg);
  };

  const onSearchAlmoxarifadoEmpresaAutoComplete = async (e: any) => {
    const {
      status,
      message: msg,
      data: almoxarifados,
    } = await getAlmoxarifadoAutoComplete({
      noAlmoxarifado: e,
      flgAtivo: true,
      nrSeqEmpresa: 'nrSeqEmpresa' in data ? data.nrSeqEmpresa : 0,
    });
    if (msg) onSetMessage(status, msg);
    return almoxarifados;
  };

  const onReleaseForCounting = async () => {
    const nrSeqControleMercadoria =
      'nrSeqControleMercadoria' in data ? data.nrSeqControleMercadoria : 0;
    await releaseForCounting(nrSeqControleMercadoria);

    await load(nrSeqControleMercadoria);
  };

  const onDownloadExcel = async () => {
    setLoading(true);
    const nrSeqControleMercadoria =
      'nrSeqControleMercadoria' in data ? data.nrSeqControleMercadoria : 0;
    const {
      value: base64,
      status,
      message: msg,
    } = await excelCheckCountingReport(nrSeqControleMercadoria);

    if (msg) onSetMessage(status, msg);
    if (base64) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `planilha_${nrSeqControleMercadoria}.xls`;
      downloadLink.click();
    }
    setLoading(false);
  };

  const onImportarNotas = async () => {
    if (!importedFile || importedFile.length === 0) {
      setMessage({
        message: 'Necessário informar as notas  para importação',
        theme: Theme.Danger,
      });
      return;
    }

    setLoading(true);

    let base64List: string[] = [];

    base64List = await onReadDocumentos();

    const { status, nrSeqControleMercadoria } = await save(
      base64List,
      undefined
    );

    setLoading(false);
    if (status === ResponseStatus.Success) {
      await load(nrSeqControleMercadoria);
    }
  };

  const handleNotasVincudas = () => {
    const notas = 'notasVinculadas' in data ? data.notasVinculadas : [];

    return notas?.length;
  };
  const onImportarChave = async () => {
    if (!key || key.length === 0) {
      setMessage({
        message: 'Necessário informar a chave da nota para importação',
        theme: Theme.Danger,
      });
      return;
    }

    const base64List: string[] = [];

    base64List.push(key);

    const { status, nrSeqControleMercadoria } = await save(
      base64List,
      undefined
    );

    if (status === ResponseStatus.Success) {
      setKey('');
      await load(nrSeqControleMercadoria);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    // @ts-expect-error
    <CSDManutPage
      isActive={isActive}
      title='Controle Embarque Mercadorias'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => {
        setData({ noTipo: 'D' });
        if (
          gridViewDocumentosDesembarque &&
          gridViewDocumentosDesembarque.current
        )
          gridViewDocumentosDesembarque.current.setDataSource([]);

        if (gridViewDocumentosEmbarque && gridViewDocumentosEmbarque.current)
          gridViewDocumentosEmbarque.current.setDataSource([]);

        if (
          gridViewDocumentosEmbarqueVincularNotas &&
          gridViewDocumentosEmbarqueVincularNotas.current
        )
          gridViewDocumentosEmbarqueVincularNotas.current.setDataSource([]);

        if (
          gridViewValidacaoContagemProdutos &&
          gridViewValidacaoContagemProdutos.current
        )
          gridViewValidacaoContagemProdutos.current.setDataSource([]);
        setImportedFile([]);
      }}
      onSave={save}
      onDelete={
        data &&
        ('nrSeqControleMercadoria' in data ? data.nrSeqControleMercadoria : 0) >
          0
          ? onExcluir
          : null
      }
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col-4 mb-4'>
          <RadioButton
            label='Tipo'
            disabled={false}
            outline
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            selectedButton={'noTipo' in data ? data.noTipo : null}
            buttons={tipo}
            onChange={(noTipo: string) => {
              setData({
                ...data,
                noTipo,
              });
            }}
          />
        </div>

        <div className='col-8 mt-3 text-end'>
          <span style={style.id}>
            {'nrSeqControleMercadoria' in data
              ? data.nrSeqControleMercadoria
              : null}
          </span>
        </div>
      </div>

      {('noTipo' in data ? data.noTipo : '') === 'D' && (
        <>
          <div className='row mb-3'>
            <div className='col-5'>
              <Autocomplete
                label='Empresa'
                required
                searchDataSource={onAutoCompleteEmpresa}
                selectedItem={'empresa' in data ? data.empresa : null}
                onSelectItem={async (empresa: Empresa) => {
                  setData({
                    ...data,
                    empresa,
                    nrSeqEmpresa: empresa.nrSeqEmpresa,
                    almoxarifado: undefined,
                    nrSeqAlmoxarifado: undefined,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
            <div className='col-5'>
              <Autocomplete
                label='Cliente'
                searchDataSource={onSearchCliente}
                selectedItem={'cliente' in data ? data.cliente : null}
                nrseqTransactionToOpen='nrSeqPessoaCli'
                onSelectItem={(cliente: Cliente) => {
                  setData({
                    ...data,
                    cliente,
                    nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
          </div>
          <div className='row mb-4'>
            <div className='col-5'>
              <Autocomplete
                label='Almoxarifado'
                required
                searchDataSource={onSearchAlmoxarifadoEmpresaAutoComplete}
                selectedItem={'almoxarifado' in data ? data.almoxarifado : null}
                onSelectItem={(almoxarifado: Almoxarifado) => {
                  setData({
                    ...data,
                    almoxarifado,
                    nrSeqAlmoxarifado: almoxarifado.nrSeqAlmoxarifado,
                  });
                }}
                dataSourceTextProperty='noAlmoxarifado'
              />
            </div>
            <div className='col-2'>
              <Textbox
                maxLength={20}
                label='Placa Veículo Desembarque'
                text={'noPlacaEntrega' in data ? data.noPlacaEntrega : null}
                onChangedValue={(noPlacaEntrega: string) =>
                  setData({
                    ...data,
                    noPlacaEntrega,
                  })
                }
              />
            </div>

            <div className='col-3'>
              <Textbox
                maxLength={20}
                label='Motorista Veículo Desembarque'
                text={
                  'noMotoristaEntrega' in data ? data.noMotoristaEntrega : null
                }
                onChangedValue={(noMotoristaEntrega: string) =>
                  setData({
                    ...data,
                    noMotoristaEntrega,
                  })
                }
              />
            </div>
          </div>

          <div className='row  mt-3 mb-3'>
            <div className='offset-6 col-2 mb-3'>
              <Switch
                formControl
                label='Exige Conferência'
                checked={
                  'flgExigeConferencia' in data
                    ? data.flgExigeConferencia ?? null
                    : null
                }
                onChange={(flgExigeConferencia: boolean) =>
                  setData({ ...data, flgExigeConferencia })
                }
              />
            </div>

            <div className='col-2'>
              <Textbox
                maxLength={20}
                readOnly
                label='Data Conferência'
                text={'dtConferencia' in data ? data.dtConferencia : null}
              />
            </div>

            <div className='col-2 offset-10'>
              <Button
                icon='plus'
                text='Liberar Conferência'
                size={BootstrapSizes.Large}
                theme={Theme.Success}
                template={Button.Templates.Default}
                onClick={() => onReleaseForCounting()}
              />
            </div>
          </div>

          <div className='row mb-3'>
            <div className='col-'>
              <FileUpload
                files={importedFile}
                onChange={(files: any) => setImportedFile(files)}
                allowedMimeTypes={[MimeTypes.Types.XML, MimeTypes.Types.Zip]}
                dropzone
                maxFiles={200}
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-2 offset-10'>
              <Button
                icon='plus'
                text='Importar Notas'
                size={BootstrapSizes.Large}
                theme={Theme.Success}
                template={Button.Templates.Default}
                onClick={() => onImportarNotas()}
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-4 offset-1'>
              <Textbox
                label='Buscar por chave da nota fiscal'
                text={key}
                onChangedValue={(value: string) => setKey(value)}
              />
            </div>
            <div className='col-2 mt-3'>
              <Button
                className='m-1'
                icon={['fas', 'search']}
                onClick={onImportarChave}
                theme={Theme.Primary}
                template={Button.Templates.Default}
              />
            </div>
          </div>

          <div className='row mb-3'>
            <div className='col-3  '>
              <Button
                text='Remover Notas'
                className='mb-3 mt-4'
                size={BootstrapSizes.Large}
                theme={Theme.Danger}
                template={Button.Templates.Default}
                onClick={onRemoveArrivalOfMaterials}
              />
            </div>

            <div className='col-4  '>
              <Button
                text='Gerar Viagem para Faturamento'
                className='mb-3 mt-4'
                size={BootstrapSizes.Large}
                theme={Theme.Success}
                template={Button.Templates.Default}
                onClick={onGenerateTravelFinancial}
              />
            </div>

            <div className='col-3 '>
              <Button
                theme={Theme.Success}
                size={BootstrapSizes.Large}
                className='mb-3 mt-4'
                icon='share'
                text='Transmitir CTes selecionados'
                onClick={transmitirCtesSelecionadosDesembarque}
              />
            </div>
          </div>

          <div className='row my-3'>
            <Panel>
              <Panel.Header
                title='Notas vinculadas neste desembarque'
                theme={Theme.Primary}
              />
              <Panel.Body>
                <div className='row'>
                  <div className='col'>
                    <GridView
                      ref={gridViewDocumentosDesembarque}
                      // @ts-expect-error
                      dataColumns={columns}
                      showSelectSizes={false}
                      showPagination={false}
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
          <div className='row'>
            <div className='col'>
              <span className='form-label'>
                Quantidade de Notas: {handleNotasVincudas()}
              </span>
            </div>
          </div>
        </>
      )}
      {('noTipo' in data ? data.noTipo : '') === 'E' && (
        <>
          <div className='row mb-3'>
            <div className='col-6'>
              <Autocomplete
                label='Empresa'
                required
                searchDataSource={onAutoCompleteEmpresa}
                selectedItem={'empresa' in data ? data.empresa : null}
                onSelectItem={async (empresa: Empresa) => {
                  setData({
                    ...data,
                    empresa,
                    nrSeqEmpresa: empresa.nrSeqEmpresa,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
          </div>

          <div className='row mb-3'>
            <div className='col-3'>
              <Autocomplete
                label='Veículo'
                searchDataSource={onAutoCompleteVeiculo}
                selectedItem={'veiculo' in data ? data.veiculo : null}
                onSelectItem={(veiculo: Veiculo) =>
                  setData({
                    ...data,
                    veiculo,
                    nrSeqVeiculo: veiculo.nrSeqVeiculo,
                  })
                }
                dataSourceTextProperty='noVeiculo'
              />
            </div>

            <div className='col-3'>
              <Autocomplete
                label='Veículo Comp A'
                tooltip='Serão listados apenas os veículos vinculados ao veículo principal'
                searchDataSource={onAutoCompleteVeiculoCompAB}
                selectedItem={'veiculoComp' in data ? data.veiculoComp : null}
                onSelectItem={(veiculoComp: Veiculo) =>
                  setData({
                    ...data,
                    veiculoComp,
                    nrSeqVeiculoComp: veiculoComp.nrSeqVeiculo,
                  })
                }
                dataSourceTextProperty='noVeiculoComp'
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-6'>
              <Autocomplete
                label='Motorista'
                tooltip='É obrigatório a seleção do veículo e apenas os motoristas cadastrados para o veículo principal serão listados'
                searchDataSource={onAutoCompleteMotorista}
                selectedItem={
                  'motorista' in data ? data.motorista ?? null : null
                }
                onSelectItem={(motorista: Motorista) => {
                  setData({
                    ...data,
                    motorista,
                    nrSeqPessoaMot: motorista.nrSeqPessoaMot,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
          </div>
          <div className='row  mt-3'>
            <div className='col-6'>
              <Autocomplete
                label='Almoxarifado'
                required
                searchDataSource={onSearchAlmoxarifadoEmpresaAutoComplete}
                selectedItem={'almoxarifado' in data ? data.almoxarifado : null}
                onSelectItem={(almoxarifado: Almoxarifado) => {
                  setData({
                    ...data,
                    almoxarifado,
                    nrSeqAlmoxarifado: almoxarifado.nrSeqAlmoxarifado,
                  });
                }}
                dataSourceTextProperty='noAlmoxarifado'
              />
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-6'>
              <Autocomplete
                label='Tipo Embarque'
                searchDataSource={onAutoCompleteTipoEmbarque}
                selectedItem={data.tipoEmbarque}
                onSelectItem={async (tipoEmbarque: any) => {
                  setData({
                    ...data,
                    tipoEmbarque,
                    nrSeqTipoEmbarque: tipoEmbarque.nrSeqTipoEmbarque,
                  });
                }}
                dataSourceTextProperty='noTipoEmbarque'
              />
            </div>
          </div>
          <div className='row  mt-3'>
            <div className='col-2 mb-3'>
              <Switch
                formControl
                label='Exige Conferência'
                checked={
                  'flgExigeConferencia' in data
                    ? data.flgExigeConferencia ?? null
                    : null
                }
                onChange={(flgExigeConferencia: boolean) =>
                  setData({ ...data, flgExigeConferencia })
                }
              />
            </div>

            <div className='col-2'>
              <Textbox
                maxLength={20}
                readOnly
                label='Data Conferência'
                text={'dtConferencia' in data ? data.dtConferencia : null}
              />
            </div>

            <div className='col-2 mt-3'>
              <Button
                icon='plus'
                text='Liberar Conferência'
                size={BootstrapSizes.Large}
                theme={Theme.Success}
                template={Button.Templates.Default}
                onClick={() => onReleaseForCounting()}
              />
            </div>
          </div>

          <div className='row mt-3'>
            <Panel>
              <Panel.Header title='Vincular notas' theme={Theme.Primary} />

              <Panel.Body>
                <div className='row mt-3 mt-1'>
                  <div className='col-2'>
                    <Textbox
                      label='Cliente'
                      text={
                        'noFornecedor' in filtros ? filtros.noFornecedor : null
                      }
                      onChangedValue={(noFornecedor: string) => {
                        setFiltros({
                          ...filtros,
                          noFornecedor,
                        });
                      }}
                    />
                  </div>

                  <div className='col-2'>
                    <Textbox
                      label='Número Nota'
                      text={'nrFornNf' in filtros ? filtros.nrForNf : null}
                      onChangedValue={(nrForNf: string) => {
                        setFiltros({
                          ...filtros,
                          nrForNf,
                        });
                      }}
                    />
                  </div>

                  <div className='col-3 mt-1'>
                    <Textbox
                      label='Cidade Destino'
                      text={
                        'noCidadeDestino' in filtros
                          ? filtros.noCidadeDestino
                          : null
                      }
                      onChangedValue={(noCidadeDestino: string) => {
                        setFiltros({
                          ...filtros,
                          noCidadeDestino,
                        });
                      }}
                    />
                  </div>

                  <div className='col-2 mt-1'>
                    <Textbox
                      label='Estado Destino'
                      text={
                        'noEstadoDestino' in filtros
                          ? filtros.noEstadoDestino
                          : null
                      }
                      onChangedValue={(noEstadoDestino: string) => {
                        setFiltros({
                          ...filtros,
                          noEstadoDestino,
                        });
                      }}
                    />
                  </div>

                  <div className='col-3 '>
                    <Button
                      text='Pesquisar Notas'
                      className='mb-3 mt-4'
                      size={BootstrapSizes.Medium}
                      theme={Theme.Success}
                      template={Button.Templates.Default}
                      onClick={onSearchNotasNaoVinculadas}
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='col'>
                    <GridView
                      ref={gridViewDocumentosEmbarqueVincularNotas}
                      // @ts-expect-error
                      dataColumns={columnsVincularNotas}
                      showSelectSizes={false}
                      showPagination={false}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-4  '>
                    <Button
                      text='Adicionar Notas'
                      className='mb-3 mt-4'
                      size={BootstrapSizes.Large}
                      theme={Theme.Success}
                      template={Button.Templates.Default}
                      onClick={onAddNotasEmbarque}
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
          <div className='row mt-3'>
            <Panel>
              <Panel.Header
                title='Notas vinculadas neste embarque'
                theme={Theme.Primary}
              />
              <Panel.Body>
                <div className='row'>
                  <div className='col-2  '>
                    <Button
                      text='Remover Notas'
                      className='mb-3 mt-4'
                      size={BootstrapSizes.Large}
                      theme={Theme.Danger}
                      template={Button.Templates.Default}
                      onClick={onRemoveDelivery}
                    />
                  </div>
                  <div className='col-3  '>
                    <Button
                      text='Gerar Viagem/CTes Pendentes'
                      className='mb-3 mt-4'
                      size={BootstrapSizes.Large}
                      theme={Theme.Success}
                      template={Button.Templates.Default}
                      onClick={onGenerateTravel}
                    />
                  </div>
                  <div className='col-3  '>
                    <Button
                      text='Gerar Conhecimento Agrupado'
                      className='mb-3 mt-4'
                      size={BootstrapSizes.Large}
                      theme={Theme.Success}
                      template={Button.Templates.Default}
                      onClick={onGenerateTravelUnique}
                    />
                  </div>
                  <div className='col-3 '>
                    <Button
                      theme={Theme.Success}
                      size={BootstrapSizes.Large}
                      className='mb-3 mt-4'
                      icon='share'
                      text='Transmitir CTes selecionados'
                      onClick={transmitirCtesSelecionados}
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='col'>
                    <GridView
                      ref={gridViewDocumentosEmbarque}
                      // @ts-expect-error
                      dataColumns={columns}
                      showSelectSizes={false}
                      showPagination={false}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    <span className='form-label'>
                      Quantidade de Notas: {handleNotasVincudas()}
                    </span>
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </>
      )}
      <>
        <div className='row mt-3'>
          <div className='col-3 offset-3'>
            <Button
              icon='print'
              text='Conferências de Produtos'
              size={BootstrapSizes.Large}
              theme={Theme.Success}
              template={Button.Templates.Default}
              onClick={() => onClickPrintCheckMaterialsReport()}
            />
          </div>
          <div className=' col-3'>
            <Button
              icon='print'
              text='Planilha de Conferência'
              size={BootstrapSizes.Large}
              theme={Theme.Success}
              template={Button.Templates.Default}
              onClick={() => onDownloadExcel()}
            />
          </div>

          <div className=' col-3'>
            <Button
              icon='print'
              text='Romaneio'
              size={BootstrapSizes.Large}
              theme={Theme.Success}
              template={Button.Templates.Default}
              onClick={() => onClickPrintDeliveryReport()}
            />
          </div>
        </div>
        <div className='row mt-3'>
          <Panel>
            <Panel.Header
              title='Contagem de Mercadorias'
              theme={Theme.Primary}
            />
            <Panel.Body>
              <div className='row'>
                <div className='col'>
                  <GridView
                    enableExcelExport
                    ref={gridViewValidacaoContagemProdutos}
                    // @ts-expect-error
                    dataColumns={columnsContagemMercadorias}
                    showSelectSizes={false}
                    showPagination={false}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </>
    </CSDManutPage>
  );
}
