import { getClienteAutoCompleteDemanda } from 'core/services/FIN';
import {
  getAparelhoAutoCompleteForDemanda,
  getChipAutoComplete,
  getPacoteSmsAutoComplete,
  getTipoChamadaAutoCompleteResumido,
} from 'core/services/TEL';
import React, { useEffect, useState, useRef } from 'react';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  BootstrapSizes,
  MimeTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  Autocomplete,
  Button,
  DatePicker,
  FileUpload,
  GridView,
  Switch,
  Textarea,
  Textbox,
} from 'ui/components';
import ModalRegistrarChipNovo from './modalRegistrarChipNovo';

export default function ManutencaoDefault({
  data,
  setData,
  onSetMessage,
  addFiles = false,
  setDataListDocRemove,
  documentos = [],
  setDocumentos,
  clienteMessage,
  contratoMessage,
  onSelectChip,
  onSelectCliente,
}) {
  const gridView = useRef();
  const [importedFile, setImportedFile] = useState([]);
  const [showModalCadastroChipNovo, setShowModalCadastroChipNovo] = useState(
    false
  );

  useEffect(() => {
    if ('nrSeqDemanda' in data) {
      if (documentos.length > 0) {
        if (gridView && gridView.current) {
          gridView.current.setDataSource(data.documentos);
        }
      }
    }
  }, [data]);

  const onSearchChip = async (e) => {
    const obj = {
      NrPrefixoLinha: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
    };

    if (data.nrSeqEmpresa) {
      obj.nrSeqEmpresa = data.nrSeqEmpresa;
    }

    const { status, message: msg, chips } = await getChipAutoComplete(obj);

    if (status === ResponseStatus.Success) {
      onSetMessage(status, msg);
    }

    return chips;
  };

  const onSearchCliente = async (e) => {
    const { clientes } = await getClienteAutoCompleteDemanda({
      noPessoa: e,
      flgIncluirEndereco: true,
      flgFiltrarEmpresa: true,
    });
    return clientes;
  };

  const onSearchPacoteSms = async (e) => {
    const obj = {
      noPacoteSms: e,
      nrSeqPlano: data.clienteLinha.nrSeqPlano,
    };

    if (data.tipoDemanda?.cdTipoDemanda.includes('Cancel')) {
      obj.nrSeqPacoteSms = data.clienteLinha.nrSeqPacoteSms;
    }

    const { pacotes } = await getPacoteSmsAutoComplete(obj);

    return pacotes;
  };

  const onSearchAparelho = async (e) => {
    const { aparelhos } = await getAparelhoAutoCompleteForDemanda({
      noAparelho: e,
    });

    return aparelhos;
  };

  const onSearchPacoteInternet = async (e) => {
    const obj = {
      nrSeqPlano: data.clienteLinha?.nrSeqPlano,
      noTipoChamada: e,
      nrSeqOperadora: data.chip.nrSeqOperadora,
      flgAssPacoteInternet: true,
      flgBuscaEncargo: true,
    };

    if (data.tipoDemanda?.cdTipoDemanda.includes('Cancel')) {
      obj.nrSeqClienteLinha = data.nrSeqClienteLinha;
    }

    const { tipoChamadas } = await getTipoChamadaAutoCompleteResumido(obj);

    return tipoChamadas;
  };

  const onClickAddDocumento = async () => {
    if (importedFile.length > 0) {
      const documento = {
        ...importedFile,
        noArquivo: importedFile[0].name,
        noTipoArquivo: importedFile[0].type,
        noTamanho: importedFile[0].size,
        flgExibeDownload: false,
        status: GridView.EnumStatus.Inserir,
      };

      const dataSource = gridView?.current?.getDataSource() ?? [];

      dataSource.push(documento);

      if (gridView && gridView.current) {
        gridView.current.setDataSource(dataSource);
      }

      setImportedFile([]);

      setDocumentos([...documentos, importedFile[0]]);
    }
  };

  const onClickDownload = async (e) => {
    const downloadLink = document.createElement('a');

    if (e.noImagem !== null && e.noImagem !== '') {
      const linkSource = `data:image/png;base64, ${e.noImagem}`;
      downloadLink.href = linkSource;
      downloadLink.download = e.noArquivo;
    } else if (e.linkDownload !== null && e.linkDownload !== '') {
      downloadLink.href = e.linkDownload;
      downloadLink.target = '_blank';
    }

    downloadLink.click();
  };

  const onRemoverDocumento = async (e, datasource) => {
    const gridItem = datasource.find((el) => el === e);

    const list = datasource.filter((el) => el !== e);

    if (gridItem.nrSeqDemandaDocumento) {
      gridItem.status = GridView.EnumStatus.Remover;

      list.push(gridItem);

      const nrSeqsToRemove = list
        .filter((item) => item.status === GridView.EnumStatus.Remover)
        .map((item) => item.nrSeqDemandaDocumento);

      setDataListDocRemove(nrSeqsToRemove);
    }

    if (gridView && gridView.current) gridView.current.setDataSource(list);
  };

  const columns = [
    { key: 'noArquivo', title: 'Documento' },
    {
      key: 'nrSeqDemandaDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickDownload(e, datasource),
      theme: Theme.Success,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
      visibleDynamic: 'flgExibeDownload',
    },
    {
      key: 'nrSeqDemandaDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemoverDocumento(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  return (
    <>
      {'cycle' in contratoMessage && data.nrSeqChip !== undefined && (
        <div className='row mb-3'>
          <div className='col'>
            <div className={`card border-${contratoMessage.color}`}>
              <div className={`card-body text-${contratoMessage.color}`}>
                <p className='card-text mb-0'>{contratoMessage.cycle}</p>
                {contratoMessage.message.length > 0 && (
                  <p className='card-text'>{contratoMessage.message}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='row mb-3'>
        {data.tipoDemanda?.flgCancelaClienteLinha && (
          <>
            <div className='col-2'>
              <DatePicker
                label='Data Cancelamento'
                readOnly
                maxLength={10}
                text={data.dtSolicitacao}
                mask={MaskTypes.Date}
                onChange={(dtSolicitacao) => {
                  setData({
                    ...data,
                    dtSolicitacao,
                  });
                }}
              />
            </div>
            {data.chip?.operadora?.noOperadora?.includes('MVNO') && (
              <div className='col'>
                <Switch
                  label='Cancelamento por Inadimplência?'
                  tooltip='Se o cancelamento for por inadimplência não é necessario fazer a demada de Retenção'
                  formControl
                  checked={!data.flgObrigaRetencao}
                  onChange={(flgObrigaRetencao) =>
                    setData({ ...data, flgObrigaRetencao: !flgObrigaRetencao })
                  }
                />
              </div>
            )}
          </>
        )}
        {data.tipoDemanda?.flgProtocolo && (
          <div className='col-5'>
            <Textbox label='Protocolo' readOnly text={data.noProtocolo} />
          </div>
        )}
      </div>
      {clienteMessage.showMessage && (
        <div className='row mb-2'>
          <div className='col'>
            <div className='card border-danger'>
              <div className='card-body text-danger'>
                <p className='card-text'>{clienteMessage.message}</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='row mb-3'>
        <div className='col-3'>
          <Autocomplete
            label='Número da linha'
            required
            maxLength={16}
            enabled={data.flgPermiteAlterar}
            searchDataSource={onSearchChip}
            selectedItem={data.chip}
            onSelectItem={async (chip) => {
              await onSelectChip(chip, true, true, false);
            }}
            dataSourceTextProperty='linhaFormatada'
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Operadora'
            selectedItem={data.chip?.operadora}
            enabled={false}
            readOnly
            dataSourceTextProperty='noOperadora'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Cliente'
            enabled={false}
            readOnly
            searchDataSource={onSearchCliente}
            minLengthTextSearch={3}
            placeholder='Digite ao menos 3 caracteres para buscar Cliente'
            selectedItem={data.cliente}
            onSelectItem={(cliente) => {
              data.nrSeqPessoaCli = cliente.nrSeqPessoaCli ?? undefined;
              data.cliente = cliente;

              onSelectCliente(cliente);
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>

      <div className='row mb-3'>
        {data.cliente?.flgFisica && (
          <>
            <div className='col-2'>
              <Textbox
                label='CPF'
                text={data.cliente?.nrDocumento}
                mask={MaskTypes.CPF}
                readOnly
              />
            </div>
            <div className='col-2'>
              <Textbox
                label='RG'
                text={data.cliente?.nrRG}
                maxLength={15}
                visible={data.cliente?.flgFisica}
                readOnly
              />
            </div>
          </>
        )}
        {!data.cliente?.flgFisica && (
          <div className='col-2'>
            <Textbox
              label='CNPJ'
              text={data.cliente?.nrDocumento}
              mask={MaskTypes.CNPJ}
              readOnly
            />
          </div>
        )}

        <div className='col'>
          <Textbox
            label='Endereço'
            text={data.cliente?.endereco?.enderecoCompleto}
            readOnly
          />
        </div>
      </div>
      {data.tipoDemanda?.cdTipoDemanda === 'Troca de Chip' &&
        data.chip?.nrSeqChip && (
          <div className='row mb-3'>
            <div className='col-3'>
              <Textbox
                label='Novo Chip (ICCID)'
                text={data.nrIccid}
                onChangedValue={(nrIccid) => setData({ ...data, nrIccid })}
                maxLength={20}
                onBlur={(_, value) => {
                  if (value.length > 0 && value.length < 20) {
                    onSetMessage(
                      ResponseStatus.Error,
                      'ICCID deve conter 20 digítos'
                    );
                  }
                }}
              />
            </div>
          </div>
        )}
      {data.tipoDemanda?.flgExibePacote && (
        <div className='row mb-3'>
          <div className='col'>
            <Autocomplete
              label='Pacote de Dados'
              required
              searchDataSource={onSearchPacoteInternet}
              selectedItem={data.tipoChamada}
              onSelectItem={(tipoChamada) => {
                setData({
                  ...data,
                  tipoChamada,
                  nrSeqTipoChamada: tipoChamada.nrSeqTipoChamada,
                  nrSeqTipoChamadaDados: tipoChamada.nrSeqTipoChamada,
                  vlrVenda: tipoChamada.vlrVenda ?? tipoChamada.vlrEncargo,
                  vlrPacoteEncargoManual:
                    tipoChamada.vlrVenda ?? tipoChamada.vlrEncargo,
                });
              }}
              dataSourceTextProperty='noTipoChamada'
            />
          </div>
          <div className='col'>
            <Textbox
              label='Valor'
              text={data.vlrVenda}
              visible={
                data.nrSeqTipoChamada > 0 || data.nrSeqTipoChamadaDados > 0
              }
              mask={MaskTypes.Decimal}
              onChangedValue={(vlrVenda) => {
                setData(...data, vlrVenda);
              }}
            />
          </div>
        </div>
      )}
      {data.tipoDemanda?.flgExibePacoteSMS && (
        <div className='row mb-3'>
          <div className='col-6'>
            <Autocomplete
              label='Pacote SMS'
              required
              searchDataSource={onSearchPacoteSms}
              selectedItem={data.pacoteSms}
              onSelectItem={(pacoteSms) => {
                setData({
                  ...data,
                  nrSeqPacoteSms: pacoteSms.nrSeqPacoteSms,
                  pacoteSms,
                });
              }}
              dataSourceTextProperty='noPacoteSms'
            />
          </div>
        </div>
      )}
      {data.tipoDemanda?.flgTrocaAparelho && (
        <div className='row mb-3'>
          <div className='col-6'>
            <Autocomplete
              label='Aparelho Novo'
              required
              searchDataSource={onSearchAparelho}
              selectedItem={data.aparelho}
              onSelectItem={(aparelho) => {
                setData({
                  ...data,
                  nrSeqAparelho: aparelho.nrSeqAparelho,
                  aparelho,
                });
              }}
              dataSourceTextProperty='noPacoteSms'
            />
          </div>
        </div>
      )}
      <div className='row mb-3'>
        <div className='col-6'>
          <Textarea
            label='Observação'
            maxLength={2000}
            readOnly={!data.flgPermiteAlterar}
            rows={5}
            text={data.noObservacao}
            onChangedValue={(noObservacao) => {
              setData({ ...data, noObservacao });
            }}
          />
        </div>
        {addFiles && (
          <div className='col'>
            <div className='row mt-4 mb-3'>
              <div className='col'>
                <FileUpload
                  files={importedFile}
                  onChange={(files) => {
                    if (files[0].size <= 5000000) {
                      setImportedFile(files);
                    } else {
                      onSetMessage(false, 'Arquivo não pode ser maior que 5MB');
                    }
                  }}
                  allowedMimeTypes={[
                    MimeTypes.Types.PDF,
                    MimeTypes.Types.Image,
                    MimeTypes.Types.Word,
                  ]}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col d-flex justify-content-center'>
                <Button
                  text='Adicionar Documento'
                  className='px-5'
                  theme={Theme.Success}
                  template={Button.Templates.Button}
                  onClick={() => onClickAddDocumento()}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {addFiles && (
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              ref={gridView}
              dataColumns={columns}
              showPagination={false}
              showSelectSizes={false}
            />
          </div>
        </div>
      )}

      <ModalRegistrarChipNovo
        data={data}
        setData={setData}
        show={showModalCadastroChipNovo}
        onClose={() => setShowModalCadastroChipNovo(false)}
      />
    </>
  );
}
